import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/Authentication";
import FirstLoader from "../utils/FirstLoader";

export const AuthFilter = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.token) {
    return <Navigate to={"/login"} state={{ path: location.pathname }} />;
  }

  if (!auth.user) {
    auth.fetchUser();
  }

  FirstLoader();

  return children;
};
