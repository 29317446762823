import { async } from "q";
import { useState, useContext, createContext } from "react";
import { Navigate } from "react-router";
import fetchApi from "../database/axios";
import { notification } from "antd";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const data = {};
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState();
  const [smsBal, setSmsBal] = useState();

  const login = async ({ token, user }) => {
    localStorage.setItem("token", token);
    setToken(token);
    setUser(user);
  };

  const logout = () => {
    localStorage.setItem("token", "");
    setToken(null);
    setUser(null);
  };

  const fetchUser = () => {
    getUnits();
    fetchApi("get", "/auth/me")
      .then((res) => {
        setUser(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          notification.error({ message: err.response.data.error });
        }
        logout();
      });
  };

  const getUnits = () => {
    fetchApi("get", "/settings/smsUnitBalance").then((res) =>
      setSmsBal(res.data.data)
    );
  };

  const updateSmsUnits = (units) => {
    fetchApi("patch", "/settings/smsUnitBalance", { value: units }).then(
      (res) => setSmsBal(res.data.data.value)
    );
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        data,
        login,
        logout,
        fetchUser,
        smsBal,
        updateSmsUnits,
        getUnits,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
