import React from 'react';
import loadable from "@loadable/component";
import { Routes, Route, Navigate } from "react-router-dom";

// Sidebar Menu
import PageLayout from '../../layouts/PageLayout';
import menu from './data/menu.json';
import Error from '../../layouts/Error404';
import Notifications from '../general/pages/Notifications/Index';
import Events from '../general/pages/Events/Index';
import { AdminFilter } from '../../filters/AdminFilter';
import Loader from '../../layouts/Loader';



const LoadablePage = loadable((props) => import(`./pages/${props.page}`), {
    fallback: <Loader />,
    cacheKey: (props) => props.page
});

const LoadDynamic = loadable((props) => import(`../${props.page}`), {
    fallback: <Loader />,
    cacheKey: (props) => props.page
});

const AdminRouter = () => {
    return (
        <React.Fragment>
            <AdminFilter >
                <PageLayout menu={menu}>
                    <Routes>
                        <Route index element={<LoadablePage page={'Dashboard'} />} />
                        <Route path="dashboard" element={<LoadablePage page="Dashboard" />} />
                        <Route path="users">
                            <Route index element={<LoadablePage page="Users/Index" />} />
                            <Route path=":userId" element={<LoadablePage page="Users/Profile" />} />
                            <Route path="create" element={<LoadablePage page="Users/Create" />} />
                        </Route>
                        <Route path="excos">
                            <Route index element={<LoadablePage page="Excos/Index" />} />
                        </Route>
                        <Route path="settings" element={<LoadablePage page="Settings/Index" />} />

                        <Route path="notifications" element={<Notifications />} />
                        <Route path="events" element={<Events />} />

                        <Route path="*" element={<Error />} />
                    </Routes>
                </PageLayout>
            </AdminFilter>
        </React.Fragment>
    )
}

export default AdminRouter;