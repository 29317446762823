import React from 'react'
import "react-big-calendar/lib/css/react-big-calendar.css"

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import events from './events'
import { Col, Row } from 'react-bootstrap'
import { Box } from '../../../../components/elements'
import { CardHeader, CardLayout } from '../../../../components/cards'
import { Card } from 'antd'

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

const MyCalendar = (props) => (
    <Row>
        <Col xl={12}>
            <Card title={'Events - Informations'}>
                <div style={{ height: 500 }}>
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                    />
                </div>
            </Card>
        </Col>
    </Row>
)

export default MyCalendar