import React from "react";
import loadable from "@loadable/component";
import { Routes, Route, Navigate } from "react-router-dom";

// Sidebar Menu
import PageLayout from "../../layouts/PageLayout";
import menu from "./data/menu.json";
import Error from "../../layouts/Error404";
import Notifications from "../general/pages/Notifications/Index";
import Events from "../general/pages/Events/Index";
import { AccountFilter } from "../../filters/AccountFilter";
import Loader from "../../layouts/Loader";

const LoadablePage = loadable((props) => import(`./pages/${props.page}`), {
  fallback: <Loader />,
  cacheKey: (props) => props.page,
});

const LoadDynamic = loadable((props) => import(`../${props.page}`), {
  fallback: <Loader />,
  cacheKey: (props) => props.page,
});

const AccountRouter = () => {
  return (
    <React.Fragment>
      <AccountFilter>
        <PageLayout menu={menu}>
          <Routes>
            <Route index element={<Navigate to="/account/dashboard" />} />
            <Route
              path="dashboard"
              element={<LoadablePage page="Dashboard" />}
            />
            <Route
              path="reports"
              element={<LoadablePage page="Reports/Index" />}
            />
            <Route
              path="bulk-sms"
              element={<LoadablePage page="BulkSMS/Create" />}
            />

            {/* ================ Bills Routes ============= */}
            <Route path="bills">
              <Route index element={<LoadablePage page="Bills/Index" />} />
              <Route
                path="create"
                element={<LoadablePage page="Bills/Create" />}
              />
              <Route
                path=":billId"
                element={<LoadablePage page="Bills/Profile" />}
              />
              <Route path="archive">
                <Route index element={<LoadablePage page="Bills/Archive" />} />
                <Route
                  path=":billId"
                  element={<LoadablePage page="Bills/Profile" />}
                />
              </Route>
            </Route>

            {/* ================ Bill Items Routes ============= */}
            <Route path="bill-items">
              <Route index element={<LoadablePage page="BillItems/Index" />} />
              <Route
                path=":billItemId"
                element={<LoadablePage page="BillItems/Profile" />}
              />
            </Route>

            {/* ================ Expenses Routes ============= */}
            <Route path="expenses">
              <Route index element={<LoadablePage page="Expenses/Index" />} />
              <Route
                path=":expenseId"
                element={<LoadablePage page="Expenses/Profile" />}
              />
            </Route>

            {/* =============== Users Routes ================ */}
            <Route path="users">
              <Route
                index
                element={<LoadDynamic page="admin/pages/Users/Index" />}
              />
              <Route
                path=":userId"
                element={<LoadDynamic page="admin/pages/Users/Profile" />}
              />
            </Route>

            {/* ================ Other Routes ============= */}
            <Route
              path="settings"
              element={<LoadablePage page="Settings/Index" />}
            />

            <Route path="notifications" element={<Notifications />} />
            <Route path="events" element={<Events />} />

            <Route path="*" element={<Error />} />
          </Routes>
        </PageLayout>
      </AccountFilter>
    </React.Fragment>
  );
};

export default AccountRouter;
