import React from "react";
import Section from "../components/elements/Section";
import { Card, Typography } from "antd";

export default function Footer() {
    const { Text } = Typography;
    return (
        <Section as="footer" className="mc-footer">
            <Card className="mc-footer-card">© All Rights Reserved by <Text strong>ZECOMS</Text> </Card>
        </Section>
    )
}