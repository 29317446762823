import React, { useContext, useState, useRef, useEffect } from "react";
import {
  LanguageDropdown,
  WidgetDropdown,
  ProfileDropdown,
} from "../components/header";
import { Typography } from "antd";
import { Button, Section, Box, Input } from "../components/elements";
import { DrawerContext } from "../context/Drawer";
import { ThemeContext } from "../context/Themes";
import { Logo } from "../components";
import { header } from "../database/header";
import { useAuth } from "../context/Authentication";
import _ from "lodash";
import { Avatar } from "antd";

export default function Header() {
  const { Text } = Typography;
  const { drawer, toggleDrawer } = useContext(DrawerContext);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const searchRef = useRef();
  const [scroll, setScroll] = useState("fixed");
  const [search, setSearch] = useState("");
  const auth = useAuth();
  const { user, smsBal } = auth;
  const [switches, setSwitches] = useState([
    { path: "/my-profile", icon: "person", text: "My Account" },
    { path: "/forgot-password", icon: "privacy_tip", text: "reset password" },
    { path: "/logout", icon: "lock", text: "logout" },
  ]);
  let items = [];

  window.addEventListener("scroll", () => {
    if (window.pageYOffset > 0) setScroll("sticky");
    else setScroll("fixed");
  });

  document.addEventListener("mousedown", (event) => {
    if (!searchRef.current?.contains(event.target)) {
      setSearch("");
    }
  });

  useEffect(() => {
    user?.roles.forEach((role) => {
      switch (role) {
        case "admin":
          items.push({
            path: "/administration",
            icon: "dashboard",
            text: "Admin Dashboard",
          });
          break;
        case "accountant":
          items.push({
            path: "/account",
            icon: "account_balance",
            text: "Accountant Dashboard",
          });
          break;
        case "user":
          items.push({
            path: "/portal",
            icon: "admin_panel_settings",
            text: "User Dashboard",
          });
          break;
        default:
          items.push({ path: "/portal", icon: "person", text: "home" });
          break;
      }
    });

    setSwitches([...items, ...switches]);
  }, [user?.roles]);

  return (
    <Section as="header" className={`mc-header ${scroll}`}>
      <Logo
        src={header?.logo.src}
        alt={header?.logo.alt}
        name={header?.logo.name}
        href={header?.logo.path}
      />
      <Box className="mc-header-group">
        <Box className="mc-header-left">
          <Button
            icon={header?.search.icon}
            className="mc-header-icon search"
            onClick={() => setSearch("show")}
          />
          <Button
            icon={drawer ? "menu_open" : "menu"}
            className="mc-header-icon toggle"
            onClick={toggleDrawer}
          />
          <Box className={`mc-header-search-group ${search}`}>
            <form className="mc-header-search" ref={searchRef}>
              <Button className="material-icons">{header?.search.icon}</Button>
              <Input type="search" placeholder={header?.search.placeholder} />
            </form>
          </Box>
        </Box>

        <Box className="mc-header-right">
          <Button
            icon={theme}
            title={header.theme.title}
            onClick={toggleTheme}
            className={`mc-header-icon ${header.theme.addClass}`}
          />

          <Text>
            Units: <Text type={"success"}>{smsBal}.00</Text>{" "}
          </Text>
          <ProfileDropdown
            name={
              user?.title ? `${user?.title} ${user?.surname}` : user?.surname
            }
            // image={user?.avatar ? user?.avatar : "https://via.placeholder.com/150"}
            image={user?.firstName.slice(0, 1).toUpperCase()}
            username={user?.firstName}
            dropdown={switches}
          />
        </Box>
      </Box>
    </Section>
  );
}
